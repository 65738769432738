<script>
import { mapState } from 'vuex';
import last from 'lodash/last';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { NOTIFICATION_TYPES } from '@/constants/notifications';
import { templateGenerators } from './templateGenerators';

export const getMessageText = (errorObj, translateFn, timezone) => {
  const {
    error,
    exception_message: exceptionMessage,
    key,
    message,
  } = errorObj;
  const fallbackMessage = message || exceptionMessage || error || translateFn('Common.Errors.default');

  if (key) {
    if (templateGenerators.has(key)) {
      const templateGenerator = templateGenerators.get(key);
      return templateGenerator({ ...errorObj, translateFn, timezone }) || fallbackMessage;
    }
    const translationKey = `Common.Errors[${key}]`;
    const translation = translateFn(translationKey);
    const translatedMessage = translation === translationKey ? fallbackMessage : translation;

    return translatedMessage;
  }
  return fallbackMessage;
};

export default {
  name: 'GlobalMessagingComponent',
  computed: {
    ...mapState(DOMAINS_MODEL.app.messages.errors.index, {
      errors: state => state.errors,
    }),
    ...mapState(DOMAINS_MODEL.app.messages.notifications.index, {
      notifications: state => state.notifications,
    }),
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
  },
  watch: {
    errors(newErrors) {
      const lastError = last(newErrors);
      const textAction = this.$t('Common.Actions.dismiss');

      this.$notify({
        message: getMessageText(lastError, this.$t, this.operatorTimezone),
        delay: lastError.delay,
        textAction,
        type: NOTIFICATION_TYPES.error,
      });
    },
  },
};
</script>

<template>
  <article class="GlobalMessagingComponent" />
</template>
